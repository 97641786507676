@import-normalize;
@import './Variables.scss';

a {
	color: inherit;
	opacity: 0.8;
	text-decoration: underline;
	&:hover {
		opacity: 1;
	}
}
.App {
	font-family: Tiempos;
}

.App-content {
	max-width: 1500px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	width: 90%;
}

.App-sidebar {
	@media (max-width: $mobile-ends) {
		background-color: $color-white;
		border-top: 1px solid $color-grey;
		bottom: 0;
		left: 0;
		padding: 0 !important;
		position: fixed !important;
		width: 100% !important;
		z-index: 10;
	}

	@media (max-width: $alt-mobile-ends) {
		z-index: 20;
	}
}

.App-content-inner {
	padding-top: 3em;
	@media (max-width: $alt-desktop-begins) {
		padding-top: 0em;
	}
	@media (max-width: $alt-mobile-ends) {
		margin-top: -3em;
		padding-top: 0;
		padding-bottom: 3em;
	}
}

.App-logo {
	width: 25%;
}

.App-link {
	color: #61dafb;
}

.App-text {
	ul, ol {
		padding-left: 0;

		li {
			display: flex;
			font-family: "Libre Franklin", sans-serif;
			margin-bottom: 1rem;
			list-style-type: none;
			position: relative;

			&:before {
				margin-right: 1em;
			}
		}
	}

	ul li:before {
		content: '—';
	}

	ol {
		counter-reset: list-counter;
	}

	ol li {
		counter-increment: list-counter;

		&:before {
			content: counter(list-counter)'.';
		}
	}

	ul.tags {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		border-bottom: 1px solid;
		padding-bottom: 2em;
		margin-bottom: 2.5em;

		&:last-child {
			border-bottom: none;
			margin-bottom: 0;
		}

		li {
			font-weight: 400;
			font-size: 1em;
			text-transform: uppercase;
			margin-right: 0.5rem;
			padding: 0 0.5rem;
			background-color: #949598;
			color: #fff;

			&.color-reduce {
				background-color: #636363;
			}

			&.color-redistribute {
				background-color: #000;
			}

			&.color-recognize {
				background-color: #B8B8B8;
				color: #000;
			}

			&.color-reward {
				background-color: #9C9C9C;
			}

			&.color-representation {
				background-color: #F0F0F0;
				color: #000;
			}
		}

		li:before {
			display: none;
		}
	}
}

.ScrollableSection {
	padding-top: 0.25em;
	.App-text &:first-of-type {
		padding-top: 2em;
		
	}
	.App-text &:nth-child(2) {
		padding-top: 0;
	}
}

.full-width-section {
	padding: 2rem 0;
}

.space-around-section {
	margin: 4em 0;
	@media ( max-width: $alt-mobile-ends ) {
		margin: 2em calc(50% - 50vw);
	}
}

.space-around-section--smaller {
	margin: 2em 0;
}

.image {
	img {
		max-width: 100%;
	}
}

.hide {
	display: none;
}

	.hide + p {
		margin-top: 0;
	}

.show {
	display: block;
}

.show-for-desktop {
	display: none;
	@media (min-width: $alt-desktop-begins) {
		display: inherit;
	}
}

.show-for-mobile {
	display: none;
	@media (max-width: $alt-mobile-ends) {
		display: inherit;
	}
}

.page_caregiving {
	@media (max-width: $alt-mobile-ends) {
		margin-top: 6em;
	}
}

.CaregivingSlide {
	background-color: #fff;
}

.Report_Download {
	background-color: $color-tan;
	padding: 2rem;

	@media (max-width: $alt-mobile-ends) {
		margin-top: 1rem;
	}

	img {
		margin: 1rem 0 0 0;
		max-width: 100%;

		@media (min-width: $alt-desktop-begins) {
			float: right;
			margin: 0.5rem 0 0.5rem 1rem;
			max-width: 150px;
		}

		@media (min-width: $mobile-ends) and (max-width: 1100px) {
			float: none;
			margin: 1rem 0 0 0;
			max-width: 100%;
		}
	}

	h4 {
		font-size: 1.5rem;
    	margin: 0;
	}

	h5 {
		font-weight: normal;
		font-size: 1.25rem;
    	margin: 0.5rem 0;
	}

	p {
		font-family: $font-family--sans-serif;
		font-size: 1rem;
		line-height: 1.5rem;
		margin-bottom: 0;
	}

	.download_button a {
		font-family: $font-family--sans-serif;
		background-color: #fff;
		border: 2px solid #01585e;
		cursor: pointer;
		display: block;
		line-height: 1.1em;
		margin: 0;
		padding: 0.5em;
		position: relative;
		width: 100%;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
	}
}

.Logo_Container {
	display: flex;
	align-items: center;
	flex-flow: wrap;

	@media (min-width: 750px) {
		justify-content: space-between;
	}
}

.Podcast_Caregiving {
	background-color: #fff;
	border-bottom: 1px solid;
	margin: 1rem auto 2rem;
	padding: 1rem 0 2rem;
	width: 100%;

	@media (min-width: $alt-desktop-begins) {
		align-items: flex-start;
		display: flex;
		flex-direction: row-reverse;
	}
	
	h4 {
		font-size: 1.25rem;
		margin: 0;
	}

	p {
		font-family: $font-family--sans-serif;
		font-size: 1rem;
		line-height: 1.35rem;
		margin-bottom: 0;
	}

	a {
		opacity: 1;
	}

	.podcast_image_container {
		@media (max-width: $alt-mobile-ends) {
			float: right;
		}
	}

	img {
		margin-bottom: 1rem;
		margin-left: 2rem;
		max-width: 150px;
	}

	audio {
		margin-top: 1rem;
		width: 100%;
	}
}

.Podcast {
	background-color: rgb(242, 169, 59);
	font-family: "Libre Franklin", sans-serif;
	.header-2 {
		font-size: 2rem;
		line-height: 1em;
		margin: 0 0 0.5em;
	}
	.header-3 {
		margin: 0 0 1em 0;
	}
	h4 {
		font-size: 1.5rem;
		line-height: 1.75rem;
		margin: 1.25em 0 0.5em;
		font-weight: normal;
	}
	&__inner {
		align-items: flex-start;
		display: flex !important;
		justify-content: space-between;
		padding-top: 1em;
		@media (max-width: $desktop-begins) {
			flex-direction: column;
		}
	}
	&__left {
		width: 40%;
		padding: 0;
		@media (max-width: $desktop-begins) {
			width: 100%;
		}
		img {
			max-width: 100%;
		}
	}
	&__right {
		padding: 0 0 0 2em;
		width: 60%;
		@media (max-width: $desktop-begins) {
			padding-left: 0;
			width: 100%;
		}
		p.quote {
			font-weight: bold;
			line-height: 1.25em;
			margin: 0;
			position: relative;
			@media (max-width: $desktop-begins) {
				margin: 1em 0 0;
			}
		}
		p.author {
			font-size: 1rem;
			line-height: 1.25rem;
		}
	}
	&__top {
		display: flex;
		align-items: center;
		width: 100%;
		@media (max-width: $alt-mobile-ends) {
			align-items: flex-start;
		}
		
		.right-content {
			padding: 0 0 0 1em;
			
			h3 {
				margin: 0;
				font-size: 1.25rem;
				line-height: 1.5rem;
				@media (max-width: $alt-mobile-ends) {
					margin-top: 0.5rem;
				}
			}

			a {
				text-decoration: none;
			}
		}
		.left-icon {
			img {
				max-width: 100px;
			}
		}
	}
}

.bullet {
	display: inline-block;
	width: 1em;
	height: 1em;
	margin-right: 0.75em;
	border-radius: 100%;
	position: relative;
	top: 3px;

	&.pink {
		background-color: #ff76c4;
	}

	&.brown {
		background-color: #c87d49;
	}

	&.blue {
		background-color: #3477d9;
	}

	&.orange {
		background-color: #f7b851;
	}
}

.StickyHeader {
	font-family: "Libre Franklin", sans-serif;
	position: sticky;
	top: 70px;
	padding: 1em 2.5rem;
	color: #000;
	font-weight: bold;
	max-width: 50%;
	z-index: 1;
	color: #fff;
	font-size: 2rem;
	@media ( max-width: $alt-mobile-ends ) {
		font-size: 1.5rem;
	}

	&.full-width {
		width: 100%;
		max-width: none;
	}
	@media (max-width: $alt-desktop-begins) {
		font-size: 1.5rem;
		max-width: 100%;
		padding: 1em 2rem;
		width: 100%;
	}
}
.no-padding {
	.Text {
		@media ( max-width: $alt-mobile-ends ) {
			padding-left: 0;
			padding-right: 0;
		}
	}
}
.generic-fonts {
	li {
		font-family: $font-family--display !important;
		font-size: 1.25rem;
    	line-height: 30px;
	}
}
.slick-slider {
	padding: 3rem 5rem;
}
	.tight-slider {
		.slick-slider {
			padding: 0;
		}
		img {
			margin-bottom: 0.5rem;
			max-width: 100%;
		}
	}
.slick-prev, 
.slick-next {
	background-color: #000 !important;
	color: transparent !important;
	cursor: pointer !important;
	font-size: 0 !important;
	line-height: 0 !important;
	padding: 30px !important;
	position: absolute !important;
	top: 45% !important;
	z-index: 1;
}
	.slick-prev:before, 
	.slick-next:before {
		content: '' !important;
		border-right: 2px solid #fff;
		border-top: 2px solid #fff;
		content: '';
		display: block;
		height: 20px;
		opacity: 1 !important;
		position: relative;
		width: 20px;
	}
	.slick-prev:before {
		margin-left: 0 !important;//-2px;
		transform: rotate(225deg);
		right: 6px;
		top: -9px;
	}
	.slick-next:before {
		margin-right: 0 !important;//-1px;
		transform: rotate(45deg);
		right: 13px;
		top: -9px;
	}
.slick-prev {
	left: 0 !important;
	margin-left: 0 !important;
	margin-right: auto !important;
}
.slick-next {
	right: 0 !important;
	margin-left: auto !important;
	margin-right: 0 !important;
}
.image{
	img {
		@media ( max-width: $alt-mobile-ends ) {
			max-width: 100% !important;
		}
	}
}
.big-number {
	font-size: 8em;
	font-family: $font-family--display;
	margin-top: auto;
	margin-bottom: auto;
	font-weight: 400;
	line-height: 1;
	display: block;

	@media ( max-width: $alt-mobile-ends ) {
		font-size: 5em;
	}
}
.caption {
	border-bottom: 1px solid;
	font-family: $font-family--sans-serif;
	font-size: 0.8rem;
	margin: 0 auto;
	padding-bottom: 0.5rem;

	@media (min-width: $desktop-begins) {
		margin: 1em auto 0;
	}
}
.two-column {
	display: flex;
	flex-direction: column;

	@media (min-width: $desktop-begins) {
		flex-direction: row;

		> div {
			flex-basis: 50%;
		}

		.text-column {
			padding-right: 2em;

			> p:first-child {
				margin-top: 0;
			}
		}
	}
}
video {
	width: 100%;
}
hr {
	margin: 2rem auto;
}