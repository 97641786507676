/* Breakpoints */
$mobile-ends: 979px;
$desktop-begins: 980px;

$alt-mobile-ends: 599px;
$alt-desktop-begins: 600px;

/* Fonts */
$font-family--display: 'Tiempos Headline', serif;
$font-family--serif: 'Tiempos', serif;
$font-family--sans-serif: 'Libre Franklin', sans-serif;

/* Colors */
$color-black: #000000;
$color-grey: #d6d6d6;
$color-tan: #ece9e4;
$color-teal: #23575e;
$color-orange: #cd543f;
$color-blue: #293e81;
$color-red: #ed3725;
$color-white: #ffffff;