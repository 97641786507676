@import '../../Variables.scss';

.Callout {
	max-width: 75%;
	margin: 3rem 0;
	border-left: 1px solid;
	padding-left: 75px;
	text-align: left;

	@media (max-width: $alt-mobile-ends) {
		max-width: none;
		padding-left: 20px;
	}

	.CalloutPreHeader {
		display: block;
		font-family: "Libre Franklin", sans-serif;
		font-size: 1.25em;
		font-weight: 600;
		line-height: 1.5em;
		margin-bottom: -1rem;
		margin-top: 0;
	}
	.CalloutHeader {
		font-size: 8em;
		font-family: $font-family--display;
		line-height: 1;
		margin-top: 0;
		font-weight: 300;
		margin: 0.5rem 0 0.5rem -0.5rem;

		@media (max-width: $alt-mobile-ends) {
			font-size: 100px !important;
		}
	}
	.CalloutText {
		font-size: 1.25em;
		line-height: 1.25em;
		margin-top: 0;
		padding-bottom: 0.5em;
		font-family: "Libre Franklin", sans-serif;
		font-weight: 600;
		max-width: 69%;
	}
}