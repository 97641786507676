@import '../../Variables.scss';

.References {
	font-family: $font-family--sans-serif;
	margin-bottom: 0;
    margin-top: 7em;
    padding-bottom: 5em;
    padding-top: 5em;

    @media (max-width: $alt-mobile-ends) {
    	margin-top: 4em;
    	padding-bottom: 3em;
    	padding-top: 3em;
    }

	ul {
		list-style-type: disc;
	}

	li {
		display: block !important;
		font-size: 0.9em;
		line-height: 1.25rem;
		padding-left: 20px;
		word-wrap: break-word; 

		&:before {
			content: '—';
			margin-left: -20px !important;
    		margin-right: 0 !important;
    		position: absolute;
		}
	}
}