@import '../../Variables.scss';

.Sidebar {
	font-family: $font-family--sans-serif;
	position: sticky;
	top: 7.7em;
	z-index: 1;

	ul {
		display: inline-block;
		height: 100%;
		list-style-type: none;
		margin: 0;
		padding: 0;

		@media (max-width: $mobile-ends) {
			width: 100%;
		}

		@media (min-width: $desktop-begins) {
			border-right: 1px solid $color-grey;
			padding: 0 3em 0 0;
		}
	}

	li {
		font-size: 0.85em;
		font-weight: bold;

		@media (max-width: $mobile-ends) {
			border-bottom: 1px solid $color-grey;
			padding: 1.5em 5%;
		}

		@media (min-width: $desktop-begins) {
			margin: 5vh 0;
		}

		a {
			color: inherit;
			position: relative;
			text-decoration: none;
		}
	}

	.DisplayAll {
		li {
			@media (max-width: $mobile-ends) {
				display: block !important;
			}
		}

		.Hide {
			display: none !important;
		}
	}

	.ReportIcon {
		@media (max-width: $mobile-ends) {
			display: none;
		}
		
		img {
			font-size: 0;
			vertical-align: bottom;
			max-width: 27px;
		}

		@media (min-width: $desktop-begins) {
			&:hover {
				.AdditionalText {
					display: block;
				}
			}
		}
	}

	.RoundedIcon {
		@media (max-width: $mobile-ends) {
			display: none;
		}

		.NumberHighlight {
			background: $color-grey;
			border-radius: 100em;
			display: inline-block;
			line-height: 0.65em;
			padding: 0.65em;
			width: 2em;
			height: 2em;
			text-align: center;

			&:hover {
				background: $color-red;
				color: $color-white;
			}
		}

		a {

			&:hover .NumberHighlight {
				background: $color-red;
				color: $color-white;
			}

			@media (min-width: $desktop-begins) {
				&:hover {
					.AdditionalText {
						display: block;
					}
				}
			}
		}
	}

	.RoundedIconActive {
		@media (max-width: $mobile-ends) {
			display: block;
		}

		.NumberHighlight {
			background: $color-red;
			color: $color-white;
		}
		.ToolTip { 
			display: none;
		}
	}

	.ImageIcon {
		@media (max-width: $mobile-ends) {
			display: none;
		}
		img {
			font-size: 0;
			vertical-align: bottom;
			max-width: 27px;
		}

		@media (min-width: $desktop-begins) {
			&:hover {
				.AdditionalText {
					display: block;
				}
			}
		}
	}

	.AdditionalText {
		@media (max-width: $mobile-ends) {
			padding: 0 0.5em;
		}

		@media (min-width: $desktop-begins) {
			background: $color-white;
			border: 1px solid $color-grey;
			color: $color-black;
			display: none;
			left: 2.5em;
			line-height: 1.3;
			min-width: 225px;
			padding: 1em;
			position: absolute;
			top:50%;
			transform: translateY(-50%);
			box-shadow: 1px 1.5px 4px 0px rgba(170, 174, 181, 0.45);
		}
		@media (min-width: $desktop-begins) {
		&:after, &:before {
			right: 100%;
			top: 50%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
		  }
		  
		  &:after {
			border-color: rgba(255, 255, 255, 0);
			border-right-color: #fff;
			border-width: 6px;
			margin-top: -6px;
		  }
		  
		  &:before {
			border-color: rgba(211, 211, 211, 0);
			border-right-color: #d3d3d3;
			border-width: 7px;
			margin-top: -7px;
		  }
		}
	}

	.AdditionalTextNumber {
		@media (max-width: $mobile-ends) {
			display: none;
		}

		@media (min-width: $desktop-begins) {
			display: inline-block;
			padding-right: 0.35em;
		}
	}

	.IconWrapper {
		align-self: center;
		display: none;
		justify-content: center;
		right: 16px;
		position: absolute;
		top: 25px;

		@media (max-width: $mobile-ends) {
			display: inline-flex;
		}
	}

	.Icon {
		height: auto;
		transform: rotateZ(180deg);
		transition: transform 0.2s ease-in-out;
		width: 16px;
		height: 16px;

		&Rotate {
			transform: rotateZ(0deg);
		}
	}

	.ToolTip {
		text-align: center;
		background: #000;
		color: #fff;
		padding: 10px;
		position: absolute;
		top: -30px;
		left: 10px;
		&::before{
			content: "";
			position: absolute;
			left:0;
			top: 100%;
			width: 0;
			height: 0;
			border-left: 0px solid transparent;
			border-right: 15px solid transparent;
			border-top: 15px solid #000;
		}
	}
	.isScrolledPast {
		.ToolTip {
			display: none;
		}
	}
}

.Hide {
	@media(min-width: $desktop-begins) {
		display: none !important;
	}
}

.Show {
	@media(min-width: $desktop-begins) {
		display: block !important;
	}
}

.ShowAlways {
	display: block !important;
}

.HideAlways {
	display: none !important;
}