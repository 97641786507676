@import '../../Variables.scss';

.IllustrationBoxes {
	position: relative;
	z-index: 10;
	min-height: calc(100vh - 70px);
	margin-bottom: 0.5rem;
}

.Background {
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	height: calc(100vh - 70px);
	position: sticky;
	top: 70px;
	z-index: -1;

	@media (min-width: $alt-desktop-begins) {
		margin-left: 50%;
		width: 50%;
	}

	@media (max-width: $alt-mobile-ends) {
		background-position: 50% top;
    	background-size: 100% auto;
    }
}

.Contents {
	position: sticky;
	margin-top: calc(-100vh + 70px);

	@media (max-width: $alt-mobile-ends) {
		padding-top: 90vw;
		position: static;
	}
}

.IllustrationBox {
	font-family: $font-family--sans-serif;
	background-color: transparent;

	@media (max-width: $alt-mobile-ends) {
		background-position: 50% 0;
		background-size: auto 50%;
		height: auto;
	}

	&:last-child {
		.Text {
			border-bottom: none;
			padding-bottom: 0;
		}
	}

	.TextContent {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0 2.5rem;
		width: 50%;
		height: 100%;
		background-color: #00585E;
		color: #fff;

		@media (max-width: $alt-mobile-ends) {
			height: auto;
			width: 100% !important;
			clip-path: none !important;
			bottom: 0;
			padding-left: 5%;
			padding-right: 5%;
		}

		//&.DownSlope {
		//	clip-path: polygon(0 0, 92% 0, 100% 100%, 0 100%);
		//}

		//&.UpSlope {
		//	clip-path: polygon(0 0, 100% 0, 92% 100%, 0 100%);
		//}

		h2 {
			margin-bottom: 0;
			margin-top: 2em;
		}

		.DownIcon {
			cursor: pointer;
			margin-top: 1rem;
			border: 1px solid #fff;
		}

		.Image:first-child {
			padding: 3em 0 0;

			@media (max-width: $alt-mobile-ends) {
				padding: 2em 0 0;
			}
		}
	}

	&:last-child .DownIcon {
		display: none;
	}

	.Image {
		padding: 1em 0 0;

		@media (max-width: $alt-mobile-ends) {
			padding: 0;
		}

		img {
			display: block;
			max-height: 55vh;
			margin: 0 auto;
			max-width: 90%;
			width: auto;
		}

	}

	.Text {
		@media (max-width: $alt-mobile-ends) {
			width: auto;
			border-bottom: 1px solid #fff;
			padding-bottom: 3rem;
			margin-bottom: 1rem;
		}
	}

}