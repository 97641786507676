@import '../../Variables.scss';

.ExpandableSection {
	margin-bottom: 1.5rem;
	&:last-child {
		margin-bottom: 0;
	}

	button {
		padding: 0;
		border: none;
		background-color: transparent;
		cursor: pointer;
		width: 100%;
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 2px solid #01585e;
		background-color: #fff;
	}

	.LeftRect {
		border-right : 2px solid #01585e;
		display: block;
		width: 20px;
		align-self: stretch;
	}

	.Title {
		margin: 0;
		font-family: $font-family--sans-serif;
		font-weight: 400;
		text-align: center;
		padding: 0.5em;
		text-align: left;
		width: 100%;
	}

	.Icons {
		align-self: stretch;
		display: flex;
		align-items: center;
	}

	.Icon {
		cursor: pointer;
		border-radius: 100%;
		margin-right: 0.5em;
	}

	.Content {
		background-color: #fff;
		border-bottom: 2px solid #01585e;
		border-left: 2px solid #01585e;
		border-right: 2px solid #01585e;
		position: relative;
		padding: 0.5rem 1.5rem;

		.IconCloseBottom {
			cursor: pointer;
			position: absolute;
			left: 50%;
			transform: translateX(-50%) translateY(-4px);
			background-color: #fff;
			border-radius: 100%;
		}
	}
	@media ( max-width: $alt-mobile-ends ) {

	p:first-of-type {
		margin-top: 0;
	}
}
}