@import '../../Variables.scss';

.FullWidthSection {
	@media (max-width: $mobile-ends) {
		margin: 2em calc(50% - 50vw);
	}

	@media (min-width: $desktop-begins) {
		margin: 4em calc(50% - 50vw);
	}
}