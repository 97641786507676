@import '../../Variables.scss';

.CaseStudies {
	.Content {
		background-color: #fff;
		font-family: $font-family--sans-serif;
		margin-top: 3rem;
		padding: 1rem 2rem 1rem;
	}
}

.CaseStudy {
	.Title {
		border: 1px solid #000;
		background-color: #fff;
		text-align: center;
		font-family: $font-family--sans-serif;
		font-size: 1em;
		font-weight: 400;
		margin-bottom: 0;
		padding: 0.25em 0;

		&.Active {
			background-color: #000;
			color: #fff;
		}
	}

	.Image {
		position: relative;
		cursor: pointer;
		padding: 1rem;
		border: 1px solid #000;
		background: #fff;
		
		&.Active {
			&::after {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 50px 50px 0 50px;
				border-color: #dcddde transparent transparent transparent;
				top: calc(100% + 1px);
			}
		}

		img {
			display: block;
			// width: 100%;
			max-height: 200px;
			margin: 0 auto;
			// object-fit: cover;

			@media (max-width: $mobile-ends) {
				display: none;
			}
		}
	}
}

.Icon {
	position: absolute;
	z-index: 10;
	bottom: -12px;
	left: 50%;
	transform: translateX(-50%);
	background-color: #fff;
	border-radius: 100%;
	cursor: pointer;
}

.MobileView {
	margin: 0.5rem 0;

	p {
		font-family: $font-family--sans-serif;
	}
}

.MobileImage {
	display: block;
	margin: 1em auto;
	max-height: 40vh;
	max-width: 100%;
	width: auto;
}