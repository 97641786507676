@import './Variables.scss';

html {
	box-sizing: border-box;
	scroll-behavior: smooth;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

html {
	color: $color-black;
	font-family: $font-family--serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5em;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
