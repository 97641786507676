@import '../../Variables.scss';

.ExecutiveSummary {
	font-family: $font-family--sans-serif;

	.Section {
		counter-reset: section;
	}

	.SubSection {
		counter-increment: section;
		display: flex;
		font-family: $font-family--sans-serif;

		&:before {
			content: counter(section) ". ";
			font-weight: bold;
			margin-right: 1em;
		}
	}

	h3 {
		font-family: $font-family--serif;
	}

	p {
		font-family: $font-family--serif;
	}

	a {
		color: inherit;
		text-decoration: none;

		&:after {
			content: ' ➞';
			margin-left: 0.25em;
			font-size: .75em;
		}
	}
}