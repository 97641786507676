@import '../../Variables.scss';

.Header {
	background-color: $color-tan;
	border-bottom: 1px solid;
	color: $color-black;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 70px;
	position: sticky;
	top: 0;
	z-index: 20;

	.App-content {
		margin-bottom: 0;
	}

	.Sections {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.Section {
		flex: 1;
		text-align: center;

		&:first-child {
			text-align: left;
			min-width: 75%;
		}
	}

	.NavLabel {
		font-family: $font-family--sans-serif;
		font-weight: 600;

		@media (max-width: $mobile-ends) {
			font-size: 1em;
			line-height: 1.25em;
			margin: 0 1rem 0 0;
		}

		@media (min-width: $desktop-begins) {
			font-size: 1.15em;
			line-height: 1.25em;
			margin: 0;
		}
	}

	.FPLogo {
		max-height: 60px;
		margin: 0;
		display: block;
		float: right;
	}
}

.Menu {
	transition: opacity 0.3s;
	opacity: 0;
	position: fixed;
	top: 70px;
	z-index: 10;
	width: 100%;
	background-color: $color-tan;
	text-align: center;
	padding-bottom: 1rem;

	&.MenuVisible {
		opacity: 1;
	}
}
