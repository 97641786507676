@import '../../Variables.scss';

.Hero {
	margin: 0 calc(50% - 50vw) 0.5rem;
	height: calc(100vh - 70px);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	position: relative;
	overflow: hidden;

	@media ( max-width: $alt-mobile-ends ) {
		height: auto;
		background-position: 50% top;
		background-size: 100% auto;
	}
	

	.HeroText {
		position: absolute;
		bottom: 0;
		max-width: 1000px;
		padding-left: 100px;
		padding-right: 100px;
		padding-top: 100px;
		transform: translateX(calc(50vw - 50%)) translateY(100%);
		background-color: #fff;

		@media ( max-width: $alt-mobile-ends ) {
			padding: 1em 0;
			width: 90%;
			margin-left: auto;
			margin-right: auto;
			margin-top: calc( 50vw + 0.5em);
			position: static;
			opacity: 1 !important;
			transform: none !important;
			height: 100%;
		}
	}
}