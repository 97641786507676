/*
Display: Tiempos Headline Light
Text: Tiempos Text Regular + Tiempos Text Regular Italic
Graphics: Libre Franklin Extrabold + Libre Franklin Semibold + Libre Franklin Regular
*/

@import './Variables.scss';

.font-display {
	font-family: $font-family--display;
}

.font-text {
	font-family: $font-family--serif;
}

.font-graphics {
	font-family: $font-family--sans-serif;
}