@import '../../Variables.scss';

.DownloadReport {
	font-family: $font-family--sans-serif;
	
	@media ( min-width: $desktop-begins ) {
		margin-top: -2em;
		margin-bottom: 3em;
	}

	.Button {
		align-items: center;
		background-color: #ebe8e3;
		border: 2px solid #01585e;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		line-height: 1.1em;
		margin: 0 0 1em;
		position: relative;
		width: 100%;

		a {
			display: block;
			padding: 0.5em;
			text-decoration: none;
		}
	}
	
}