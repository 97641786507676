@import './Variables.scss';

p, li {
	font-size: 1.25rem;
	line-height: 1.5em;
}

.author-text {
	font-style: italic;
	margin-top: 0.25em;
}

.page-title {
	font-family: $font-family--display;
	font-size: 4.375em;
	font-weight: 400;
	line-height: 4.6875rem;
	padding-bottom: 2rem;
	margin-bottom: 0;
	@media (max-width: $alt-desktop-begins) {	
		font-size: 2em;
		line-height: 2.75rem;
	}
}

.header-h2 {
	font-family: $font-family--display;
	font-size: 2.25em;
	line-height: 3.125rem;
	font-weight: 400;
	align-self: flex-start;
	padding-bottom: 1rem;
	@media (max-width: $alt-desktop-begins) {
		font-size: 2em;
	}
	@media (max-width: $alt-mobile-ends) {
		font-size: 1.75em;
		line-height: 2.25rem;
	}

	.page-title + &,
	p + & {
		margin-top: 1.75em;
		@media (max-width: $alt-mobile-ends) {
			margin-top: 2em;
		}
	}
}

.header-h3 {
	font-family: $font-family--display;
	font-size: 1.75em;
	line-height: 2.25rem;
	font-weight: 400;
	align-self: flex-start;
	padding-bottom: 1.5rem;

	.header-h2 + &,
	.header-h2 + .anchor + & {
		margin-top: 0;
	}

	& + p {
		margin-top: 0.5rem;
	}

	@media (max-width: $alt-mobile-ends){
		font-size: 1.5em;
		line-height: 2rem;
	}
}

.header-h3-sans {
	font-family: $font-family--sans-serif;
	font-size: 1.75em;
	line-height: 2.25rem;
	font-weight: 800;
	margin-bottom: 1rem;
	margin-top: 3.5rem;
}

.page-title,
.header-h2,
.header-h3 {
	position: relative;
    clear: both;

    &:after {
		border-bottom: 6px solid #000;
		bottom: 0;
		content: '';
		left: 0;
		position: absolute;
		width: 100%;
    }
}

.page-title {
	display: inline;
}

.page-title--no-border {
	display: block;

	&:after {
		display: none;
	}

	& + h2 {
		margin-top: 0 !important;
	}
}

.header-h2,
.header-h3 {
	display: inline-block;
}

.tight-top {
	margin-top: 0;
}

.instructional-subhead {
	font-size: 1em;
	line-height: 1.5em;
	margin-bottom: 0.5em;
	margin-top: -1em;
}

.space-bottom {
	margin-bottom: 1.5em;
}

.space-bottom--mobile {
	@media (max-width: $alt-mobile-ends){
		margin-bottom: 2em;
	}
}

.no-border {
	display: block;
	padding-bottom: 0;
	top: auto;

	&:after {
		display: none;
	}
}

ul.font-weight-500 {
	li {
		font-weight: 500;
	}
}

.text-center {
	text-align: center;
}