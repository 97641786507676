@import '../../Variables.scss';

.Callout {
	max-width: 75%;
	margin: 3rem 0;
	border-left: 1px solid;
	padding-left: 4em;
	text-align: left;
	font-family: $font-family--display;

	@media (max-width: $alt-mobile-ends) {
		max-width: none;
		padding-left: 20px;
	}

	.CalloutHeader {
		font-family: $font-family--display;
		font-size: 40px;
		line-height: 46px;
		margin-top: 0;
		font-weight: 400;
	}

	img {
		max-width: 100%;
	}
}

.CalloutGraphic {

	.CalloutHeader {
		font-family: $font-family--sans-serif;
		font-size: 1.25rem;
		font-weight: 600;
		line-height: 1.5em;
	}

	p,
	li {
		font-family: $font-family--sans-serif !important;
	}
}