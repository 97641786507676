@import '../../Variables.scss';

.Spotlight {
	margin: 3em 0;
	width: 100%;
	

	.ImageContainer {
		padding: 1rem 2.5rem;
	}

	&.ImageAbove {
		.ImageContainer {
			margin: 0;
			width: 55%;
			
			@media ( max-width: $alt-mobile-ends ) {
				width: 100%;
			}
		}
	}

	&.ImageRight,
	&.ImageLeft {
		padding: 2em;
		display: flex;
		justify-content: space-between;
		//align-items: center;

		@media ( max-width: $alt-mobile-ends ) {
			flex-direction: column;
		}

		.ImageContainer {
			width: 50%;
			padding: 1rem 2.5rem;

			@media ( max-width: $alt-mobile-ends ) {
				width: 100%;
			}
		}

		.Text {
			width: 50%;
			padding: 1rem 2rem;

			@media ( max-width: $alt-mobile-ends ) {
				width: 100%;
				padding: 0 0 1rem 0;
			}
		}
	}

	&.ImageRight {
		.ImageContainer {
			@media ( min-width: $alt-desktop-begins ) {
				padding-left: 0;
				padding-right: 2.5rem;
			}
		}
	}
	&.ImageLeft {
		.ImageContainer {
			@media ( min-width: $alt-desktop-begins ) {
				padding-left: 2.5rem;
				padding-right: 0;
			}
		}
	}

	&.ImageRight {
		flex-direction: row-reverse;
		@media ( max-width: $alt-mobile-ends ) {
			flex-direction: column;
			
		}
	}

	&.SmallPadding {
		.Text {
			padding: 2.5rem;
		}
	}

	&.NoPadding {
		.Text {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&.NoImageAddBorder {
		border-left: 1px solid;

		.Text {
			padding: 3.125em 0 3.125em 3.125em;

			@media ( max-width: $alt-mobile-ends ) {
				padding-left: 2em;
				padding-right: 1.5em;
			}
		}
	}

	&.HeaderImage {
		.ImageContainer {
			padding: 0;
		}
	}

	.Image {
		width: 100%;

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	.Text {
		//@media ( max-width: $alt-mobile-ends ) {
		//	padding-left: 50px;
		//	padding-right: 50px;
		//}

		&.HasFade {
			overflow: hidden;
			position: relative;
		}

		.Fade {
			position: absolute;
			bottom: 0;
			width: 100%;
			left: 0;
			background: linear-gradient(0deg,rgba(75,75,75,0.75) 20%,rgba(255,255,255,0) 100%);
			height: 100px;
			display: flex;
			justify-content: center;
			align-items: flex-end;
			padding-bottom: 1rem;
		}

		.Icon {
			cursor: pointer;
		}

		p:first-child {
			margin-top: 0;
		}

		:last-child {
			margin-bottom: 0;
		}

		font-family: $font-family--sans-serif;
		padding: 2.5rem;

		@media (max-width: $alt-desktop-begins) {
			padding: 2rem;
		}

		.Header {
			font-size: 2em;
			margin-top: 0;
			@media (max-width: $alt-mobile-ends) {
				font-size: 1.5em;
				line-height: 2.25rem;
			}
		}

		.SubHeader {
			font-family: $font-family--sans-serif;
			font-size: 1.5em;
			@media (max-width: $alt-mobile-ends) {
				font-size: 1.25em;
				line-height: 2rem;
			}
		}

	}

	&.TightText {
		padding: 1rem 0;

		.Text {
			padding: 1rem 2.5rem;

			@media (max-width: $alt-desktop-begins) {
				padding: 1rem 2rem;
			}
		}
	}

	&.TightTop {
		margin-top: -2em !important;
	}

}
