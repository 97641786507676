.row {
	display: flex;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
	padding-left: 15px;
	padding-right: 15px;
	position: relative;
	width: 100%;
}

@media (min-width: 980px) {
	.col-1 { 
		flex: 0 0 8.33333%;
		max-width: 8.33333%;
	}
	.col-2 { 
		flex: 0 0 16.66667%;
		max-width: 16.66667%;
	}
	.col-3 { 
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-4 { 
		flex: 0 0 33.33333%;
		max-width: 33.33333%;
	}
	.col-5 { 
		flex: 0 0 41.66667%;
		max-width: 41.66667%;
	}
	.col-6 { 
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-7 { 
		flex: 0 0 58.33333%;
		max-width: 58.33333%;
	}
	.col-8 { 
		flex: 0 0 66.66667%;
		max-width: 66.66667%;
	}
	.col-9 { 
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-10 { 
		flex: 0 0 83.33333%;
		max-width: 83.33333%;
	}
	.col-11 { 
		flex: 0 0 91.66667%;
		max-width: 91.66667%;
	}
	.col-12 { 
		flex: 0 0 100%;
		max-width: 100%;
	}
}
